@media screen and (max-width: 400px) {
    .dashboard-content {
        padding: 20px;

    }

}

.MuiAppBar-colorPrimary {
    background-color: rgba(17, 23, 53, 0.87);
}