.personal-transactions-holder {
    .itmt-options {
        padding-left: 30px;
        font-size: 0.75rem;
        font-weight: 400;
        color: rgba(65, 65, 65, 0.966);
    }

    max-width: 1000px;
    padding-left: 0;
    margin-top: 20px;

    .loan-schedule-head {
        margin-top: 20px;
        max-height: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: #2E334E;

        .detail-schedule {
            padding: 5px;
            display: flex;
            flex-direction: column;
            padding-left: 25px;
        }

        .head-content {
            width: 110px;
            min-width: 110px;
        }

        .head-name {
            width: 150px;
            min-width: 150px;
        }

        .head-date {
            width: 100px;
            min-width: 100px;
        }

        .head-comment {
            width: 170px;
            min-width: 170px
        }

        .head-concept {
            width: 130px;
            min-width: 130px
        }
    }

    .loan-schedule-content {
        max-height: 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-bottom: 1px solid;
        border-color: #EBF4FD;
        background-color: #EBF4FD;

        .detail-schedule {
            padding: 5px;
            display: flex;
            flex-direction: column;
            padding-left: 25px;
        }

        .details-comment {
            width: 170px;
            min-width: 170px;

            p {
                text-align: right;
                font-size: 0.75rem;
                font-weight: 400;
                color: rgba(54, 54, 54, 0.966);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .details-date {
            width: 100px;
            min-width: 100px;
        }

        .details-content {
            width: 110px;
            min-width: 110px
        }

        .details-name {
            width: 150px;
            min-width: 150px;
        }

        .details-concept {
            width: 130px;
            min-width: 130px;
        }

        .acc-date {
            font-size: 0.75rem;
            font-weight: 400;
            color: rgba(54, 54, 54, 0.966);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .acc-total {
            text-align: right;
            font-size: 0.75rem;
            font-weight: 400;
            color: rgba(54, 54, 54, 0.966)
        }
    }

    .loan-schedule-content:nth-child(odd) {
        background-color: #EBF4FD;
    }

    .loan-schedule-content:nth-child(even) {
        background-color: rgb(214, 235, 255);
    }


    p {
        margin: 0;
        padding: 0;
    }

    .title {
        text-align: right;
        font-weight: 600;
        letter-spacing: 0.08rem;
        color: rgb(223, 223, 223)
    }

    .title-date {
        text-align: left;
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: rgb(223, 223, 223);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .total {
        font-size: 1.5rem;
        font-weight: 300;
        color: rgba(206, 247, 252, 0.815)
    }

    .acc-title {
        margin-top: 5px;
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: rgba(149, 222, 231, 0.952)
    }

}