.filterBar {
  width: 100%;
  display: flex;
  flex-direction: row;

  .filterItem {
    margin-top: 10px;
    margin-left: 20px;
  }
}

.result-table {
  width: 100%;
  overflow-x: auto;
}

.slider {
  margin-top: 18px;
}
