.title-app-bar {
    font-size: 1.1em;
    font-weight: 600;
    padding: 0;
    margin: 0;
    margin-top: 10px;
}

.id-identifier {
    font-size: 0.9em;
    font-weight: 400;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
}

.bar-dropdown {
    margin-top: 6px;
    font-weight: 600;
}

.link-pop {
    text-decoration: none;
}

.link-navbar {
    text-decoration: none;
}

.popover-icons {
    color: #2D4369;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.account-status-notify {
    background-color: rgba(250, 164, 93, 0.801);
    padding: 10px;
    padding-left: 20px;
    height: 45px;

    p {

        font-weight: 600;
    }
}