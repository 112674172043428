
.modal-holder {
    max-width: 800px;
    
}
.edit-form-submit {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

}
.form-inputs-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
    color: '#2D4369',
}