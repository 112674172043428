@charset "utf-8";

html {
    font-size: 14px;
}

body {
    font-size: 10px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    margin: 0;
}

h1 {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 26.4px;
}

h3 {
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 15.4px;
}

h4 {
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 15.4px;
}

p {
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 20px;
}

blockquote {
    font-family: Roboto;
    font-size: 21px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 30px;
}

pre {
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 18.5714px;
}

.content {
    width: 1440px*0.9;
    margin-left: 20px;
    margin-top: 10px;
}

.search-bar {
    width: 1000px;
}




.client-summary {
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;

    p {
        margin: 0;
        padding: 0;
        color: rgba(53, 53, 53, 0.912)
    }

    h4 {
        margin: 0;
        margin-bottom: 3px;
        padding: 0;
        font-weight: 400;
        font-size: 0.9rem;
        color: rgb(53, 53, 53)
    }
}



.user-global-information {
    display: flex;
    flex-direction: row;

    .client-loan-information {
        width: 750px;
        margin-left: 20px;

        p {
            margin: 0;
            padding: 0;
        }

        .title {
            font-weight: 600;
            letter-spacing: 0.1rem;
            color: rgba(50, 96, 102, 0.952)
        }

        .total {
            font-size: 1rem;
            font-weight: 300;
            color: rgba(255, 255, 255, 0.815)
        }

        .acc-title {
            margin-top: 5px;
            font-weight: 600;
            letter-spacing: 0.1rem;
            color: rgba(255, 255, 255, 0.952)
        }

        .acc-total {
            margin-bottom: 2.5;
            font-size: 0.75rem;
            font-weight: 300;
            color: rgba(255, 255, 255, 0.815)
        }

        .client-loan-summary-head {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            min-height: 60px;
            background-color: rgb(155, 224, 235);
            border-radius: 10px 10px 0 0;

            .detail-summary {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 20px;
                padding-left: 25px;
                min-width: 200px;
            }

            .first {
                min-width: 230px;
            }

        }

        .client-loan-summary {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            min-height: 80px;
            border-bottom: 1px solid;
            border-color: rgba(207, 207, 207, 0.322);
            background-color: rgba(0, 86, 136, 1);

            .detail-summary {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 15px;
                padding-left: 25px;
                min-width: 200px;
            }

            .first {
                min-width: 230px;
            }

            .last {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .info {
                    color: rgba(129, 200, 212, 0.815);
                    align-items: flex-end;
                }
            }

        }

        .client-loan-summary:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }





    .user-information {
        display: flex;
        flex-direction: column;

        .user-profile-holder {
            width: 500px;
            margin-left: 20px;

            .section-header {
                font-size: 1.2em;
                font-weight: 700;
                border-bottom: 1px solid;
                border-bottom-color: rgba(0, 86, 136, 1);
                margin-bottom: 20px;
            }

            .user-detail {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 20;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                height: 10;

                .detail-key {
                    margin: 0;
                    padding: 0;
                    font-size: 0.8em;
                    font-weight: 400;
                    min-width: 250px;
                }

                .detail-value {
                    margin: 0;
                    padding: 0;
                    height: 10;
                    font-size: 1em;
                    font-weight: 400;
                    width: 250px;
                }


            }
        }
    }
}


.general-loan-details {
    margin-top: 20px;

    .card {
        margin-top: 20px;
    }

    table-holder {
        padding: 20 0 20 0;
    }

    .investors {
        width: 100%;
    }
}


.loan-summary-head {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 60px;
    background-color: rgb(155, 224, 235);
    border-radius: 10px 10px 0 0;

    p {
        margin: 0;
        padding: 0;
    }

    .title {
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: rgba(50, 96, 102, 0.952)
    }

    .total {
        font-size: 1.5rem;
        font-weight: 300;
        color: rgba(206, 247, 252, 0.815)
    }

    .acc-title {
        margin-top: 5px;
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: rgba(149, 222, 231, 0.952)
    }

    .acc-total {
        margin-bottom: 2.5;
        font-size: 0.75rem;
        font-weight: 300;
        color: rgba(218, 251, 255, 0.815)
    }

    .detail-summary {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 20px;
        padding-left: 25px;
    }

    .head-left {
        min-width: 350px;
    }

    .head-content {
        min-width: 270px;
    }

    .last {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .loan-delete {
            color: rgba(218, 251, 255, 0.815);
            align-items: flex-end;
        }
    }

}




.loan-schedule-holder {
    max-width: 800px;

    .loan-schedule-head {
        margin-top: 20px;
        margin-left: 20px;
        max-height: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: rgb(155, 224, 235);
        border-radius: 10px 10px 0 0;

        .detail-schedule {
            padding: 5px;
            display: flex;
            flex-direction: column;
            padding-left: 25px;
        }

        .head-content {
            width: 112px;
            min-width: 112px;
        }

        .head-date {
            width: 90px;
            min-width: 90px;
        }
    }

    .loan-schedule-content {
        margin-left: 20px;
        max-height: 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-bottom: 1px solid;
        border-color: rgba(97, 97, 97, 0.336);
        background-color: rgba(0, 86, 136, 1);

        .detail-schedule {
            padding: 5px;
            display: flex;
            flex-direction: column;
            padding-left: 25px;
        }

        .details-content {
            width: 112px;
            min-width: 112px;
        }

        .details-date {
            width: 90px;
            min-width: 90px;
        }

        .acc-total {
            font-size: 0.75rem;
            font-weight: 400;
            color: rgba(244, 254, 255, 0.966)
        }
    }

    .loan-payment-content {
        margin-top: 20px;
        margin-left: 20px;
        max-height: 100px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-bottom: 1px solid;
        border-color: rgba(97, 97, 97, 0.336);
        background-color: rgba(0, 86, 136, 1);
        border-radius: 10px;

        .detail-schedule:first-child {
            padding-left: 25px;
        }

        .detail-schedule {

            padding: 5px;
            display: flex;
            flex-direction: column;
            padding-left: 15px;
            width: 112px;
            min-width: 112px;
            height: 120px;

            .last {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .button {
                    width: 5px;
                    height: 40px;
                    padding: 0;
                    margin-left: 5px;
                }

                .icon {
                    height: 30px;
                    width: 30px;
                    padding: 0;
                    margin: 0 auto;
                }

                .icon-s {
                    height: 30px;
                    width: 30px;
                    padding: 0;
                    margin: 0 auto;
                    margin-top: 4px;
                }
            }
        }

        .details-date {
            color: rgba(40, 80, 112, 0.966);
            width: 200px;
            min-width: 90px;
        }

        .acc-total {
            font-size: 0.75rem;
            font-weight: 400;
            color: rgba(244, 254, 255, 0.966)
        }

        .textField {
            font-size: 0.8rem;

        }

    }

    .payment-operations {
        display: flex;
        flex-direction: row;

        i {
            font-size: 3em;
            margin: 2px;
        }
    }

    .loan-schedule-content:last-child {
        border-radius: 0 0 10px 10px;
    }

    p {
        margin: 0;
        padding: 0;
    }

    .title {
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: rgba(50, 96, 102, 0.952)
    }

    .total {
        font-size: 1.5rem;
        font-weight: 300;
        color: rgba(206, 247, 252, 0.815)
    }

    .acc-title {
        margin-top: 5px;
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: rgba(149, 222, 231, 0.952)
    }




}

.loan-delete {
    width: 1000px;
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
}







.loan-summary {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 100px;
    border-bottom: 1px solid;
    border-color: rgba(207, 207, 207, 0.322);
    background-color: rgba(0, 86, 136, 1);

    p {
        margin: 0;
        padding: 0;
    }

    .title {
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: rgba(135, 207, 216, 0.952)
    }

    .total {
        font-size: 1.5rem;
        font-weight: 300;
        color: rgba(254, 254, 254, 1)
    }

    .acc-title {
        margin-top: 5px;
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: rgba(149, 222, 231, 0.952)
    }

    .acc-total {
        margin-bottom: 2.5;
        font-size: 0.75rem;
        font-weight: 300;
        color: rgba(254, 254, 254, 1)
    }

    .detail-summary {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 15px;
        padding-left: 25px;
    }

    .detail-left {
        min-width: 350px;
    }

    .detail-content {
        min-width: 270px;
    }

    .last {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .loan-delete {
            color: rgba(218, 251, 255, 0.815);
            align-items: flex-end;
        }
    }

}

.loans-summary {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: rgb(76, 120, 161);
    border-radius: 10px;
    margin-bottom: 20px;

    p {
        margin: 0;
        padding: 0;
    }

    .title {
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: rgba(162, 228, 248, 1)
    }

    .total {
        font-size: 2rem;
        font-weight: 300;
        color: rgba(254, 254, 254, 1)
    }

    .acc-title {
        margin-top: 5px;
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: rgba(162, 228, 248, 1)
    }

    .acc-total {
        margin-bottom: 2.5;
        font-size: 1rem;
        font-weight: 300;
        color: rgba(254, 254, 254, 1)
    }

    .detail-summary {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 20px;
        padding-left: 25px;
        ;
        min-width: 290px;
    }

    .center {
        border-right: 1px solid;
        border-left: 1px solid;
        border-color: rgba(190, 190, 190, 0.322);
    }

}

.select-size {
    width: 827px;
    margin-left: 5px;
    margin-right: 7px;
}

.loan-content-holder {
    width: 1000px;
    display: flex;
    flex-wrap: wrap;

}



.loan-details-summary {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: rgba(17, 23, 53, 0.87);
    border-radius: 10px;

    p {
        margin: 0;
        padding: 0;
    }

    .title {
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: rgba(162, 228, 248, 1)
    }

    .total {
        font-size: 2rem;
        font-weight: 300;
        color: rgba(254, 254, 254, 1)
    }

    .acc-title {
        margin-top: 5px;
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: rgba(162, 228, 248, 1)
    }

    .acc-total {
        margin-bottom: 2.5;
        font-size: 1rem;
        font-weight: 300;
        color: rgba(254, 254, 254, 1)
    }

    .detail-summary {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 20px;
        padding-left: 25px;
        min-width: 290px;
    }

    // .detail-left {
    //     min-width: 370px;
    // }
    // .detail-content {
    //     min-width: 300px; 
    // }
    .center {
        border-right: 1px solid;
        border-left: 1px solid;
        border-color: rgba(190, 190, 190, 0.322);
    }

}




.acc-summary {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 250px;
    background-color: rgba(17, 23, 53, 0.87);
    border-radius: 10px;

    p {
        margin: 0;
        padding: 0;
    }

    .title {
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: rgba(114, 200, 211, 0.952)
    }

    .total {
        font-size: 2rem;
        font-weight: 300;
        color: rgba(197, 249, 255, 0.815)
    }

    .acc-title {
        margin-top: 5px;
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: rgba(114, 200, 211, 0.952)
    }

    .acc-total {
        margin-bottom: 2.5;
        font-size: 1rem;
        font-weight: 300;
        color: rgba(197, 249, 255, 0.815)
    }

    .detail-summary {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 20px;
        width: 25%;
    }

    .center {
        border-right: 1px solid;
        border-left: 1px solid;
        border-color: rgba(128, 128, 128, 0.322);
    }

}

.contentForm {

    width: 1336px;
    margin: 0 auto;
    margin-top: 20px;
    background: rgb(250, 250, 250);
    padding: 5px;
    padding-top: 50px;
    border: 1px solid;
    border-color: rgba(128, 128, 128, 0.151);
    border-radius: 10px;

    .formHeaderHolder {
        width: 95%;
        margin: 0 auto;
        margin-bottom: 20px;

        .formHeader {
            font-size: 0.5rem;
        }
    }

    .formSectionHolder {

        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .formSubmitHolder {
            width: 75%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .btn {
                width: 10em;
                margin: 0;
                margin-bottom: 2em;
            }

            .alert {
                width: 100%;
            }
        }

        .formTitleHolder {
            border-top: 1px solid;
            border-color: gray;
            padding-top: 15px;
            padding-bottom: 15px;
            width: 25%;

            p {
                margin-top: 16px;
            }
        }

        .formInputsHolder {
            border-top: 1px solid;
            border-color: gray;
            padding-top: 15px;
            padding-bottom: 15px;
            width: 75%;
            margin-bottom: 20px;
        }

        .formLastSubmitHolder {
            padding-top: 15px;
            padding-bottom: 15px;
            width: 25%;

            p {
                margin-top: 16px;
            }
        }
    }
}

.pm-btn {
    background-color: #44c767;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 11px;
    padding: 3px 20px;
    text-decoration: none;
}

.input-row-aligner {
    display: flex;
    flex-direction: row;
    align-items: flex-end
}

.row-elements {
    font-size: inherit;
    padding: 0.2em;
    margin: 0.1em 0.2em;
    /* the following ensures they're all using the same box-model for rendering */
    -moz-box-sizing: content-box;
    /* or `border-box` */
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.btn {
    margin-right: 7px;
}

.payment-schedule {
    margin-top: 20px;
}

.del-btn {
    background-color: #c42700;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 11px;
    padding: 3px 20px;
    text-decoration: none;
}

.nav-drop-text {
    font-size: 12px;
}

.table td,
.table th {
    padding: .2rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.nav-list {
    display: flex;
    justify-content: space-between;

}


.navbar {
    padding-left: 10%;
    padding-right: 10%;
    background-color: rgb(16, 19, 34);

    .nav-link {
        color: white;
    }
}

.img-nav {
    height: auto;
    width: auto;
    max-height: 50px;

}


.line-distrib-list {
    display: flex;
    list-style: none;
}

.line-distrib-list li {
    margin-right: 0.8em;
}

.AccDetailsHolder {
    display: flex;
    flex-direction: row;

    .AccCategoryHolder {
        display: flex;
        // flex-wrap: wrap;
        justify-content: stretch;
        flex-direction: column;

        .AccDetailSquare {
            margin: 3px;
            width: 190px;
            height: 120px;
            background-color: rgb(0, 98, 190);
            display: flex;
            align-items: center;
            justify-content: center;

            .DetailSquareText {
                max-width: 80%;
            }
        }
    }
}


.AccDetailSquare h2 {
    color: white;
    align-self: center;
    font-size: 25px;
}

.AccDetailSquare h6 {
    color: white;
    align-self: center;
    font-size: 12px;
}


* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    position: relative;
    // background-color: rgba(0, 177, 197, 0.089)
}

.asyncSelect {
    color: black;
}

.App {
    min-height: 100vh;
    /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    background-color: #F2F5F7;
    position: relative;
    padding-bottom: 100px;
    /* height of your footer */
}

.App-auth {
    min-height: 100vh;
    /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    background-color: #F2F5F7;
    position: relative;
    // padding-bottom: 100px; /* height of your footer */
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.jumbotron {
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 25px;
}

.reducer {
    height: 38px;
}


@media only screen and (max-width: 600px) {
    .content {
        margin: 0 auto;
        width: 90%;
    }
}