.access-holder {
    display: flex;
    width: 100%;

}

.access-window {
    margin: 0 auto;

    width: 60%;
    min-height: 100vh;
}

.access-window-title {
    font-weight: 600;
    font-size: 1.5rem;
    color: rgba(15, 20, 51, 0.829);
}

.access-window-subtitle {
    font-weight: 400;
    font-size: 1rem;
    color: rgba(15, 20, 51, 0.829);
    text-align: center;
}

.access-notify-failure {
    width: 100%;
    align-content: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: -5px;
    text-align: center;
    background-color: rgb(255, 78, 61);
    color: white;
    // rgb(58, 255, 196);
    height: 70px;

    p {
        margin-top: 12px;
    }
}

.error-notification {
    width: 100%;
    align-content: center;
    border-radius: 5px;
    margin: 10px;
    text-align: center;
    background-color: rgba(255, 77, 61, 0.788);
    color: white;
    // rgb(58, 255, 196);
    height: 70px;

    h1 {
        margin-top: 20px;
        font-size: 1em;
    }
}

.access-notify-success {
    width: 100%;
    align-content: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    margin: -5px;
    background-color: rgb(58, 255, 196);
    ;
    height: 50px;

    p {
        margin-top: 12px;
    }
}

.access-window-note {
    width: 100%;
    font-weight: 400;
    font-size: 0.8rem;
    color: rgba(15, 20, 51, 0.829);
    margin-top: 10px;
    text-align: center;
}

.logo-access {
    background-image: url('https://res.cloudinary.com/ribo/image/upload/v1556958263/5ca4310f6408d129be4221b1_Logo_RIBO-03.png');
    background-size: 200px;
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    margin: 0 auto;
    margin-top: 15%;
    width: 60%;
}


.logo-access-reg {
    background-image: url('https://res.cloudinary.com/ribo/image/upload/v1556958263/5ca4310f6408d129be4221b1_Logo_RIBO-03.png');
    background-size: 200px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    margin-top: 30px;
    height: 200px;
    width: 60%;
}

.user-credentials {
    height: 500px;
    width: 450px;
    margin: 0 auto;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.entry {
    background-image: url('https://res.cloudinary.com/ribo/image/upload/v1556973966/background.jpg');
    background-size: 1250px;
    background-repeat: no-repeat;
    background-position: 45% 50%;
    display: block;
    width: 40%;

}

.credentials-form-holder {
    margin: 0 auto;
    margin-top: 50px;
    width: 30%;
}

@media all and (max-width: 767px) {
    .entry {
        visibility: hidden;
    }

    .access-holder {
        display: flex;
        flex-direction: column;
    }

    .logo-access {
        width: 80%;
    }

    .user-credentials {
        width: 100%;
    }

    .access-window {
        width: 80%;
    }
}