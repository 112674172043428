.divider-small {
  margin: 20px 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.inner-component {
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 788px;
  min-width: 300px;
}

.inner-second-component {
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.row-content {
  padding: 10px 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex-wrap {
  width: 100%;
  display: flex;
  // flex-direction: row;
  justify-content: space-evenly;
}

.content-padding {
  width: 100%;
  padding: 1.5rem;
}

// .main-component-holder {
//     margin: 20px auto;
//     display: flex;
//     flex-direction: column;
//     width:
// }

.grid-container {
  display: grid;
  grid-template-columns: auto 380px;
  grid-template-rows: auto;
  grid-template-areas: "content sidebar";
}

.grid-content {
  grid-area: content;
}

.sidebar {
  grid-area: sidebar;
  margin-top: 0px;
  display: flex;
}

.graph-container {
  padding: 10px 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0 10px;
  grid-template-areas: "graph details";
}

.graph-view {
  grid-area: graph;
  grid-column: 1 / 7;
}

.graph-detail-view {
  grid-area: details;
  grid-column: 7 / -1;
}

.sidebar-container {
  // padding: 1.5rem;
  width: 100%;
  height: 100%;

  .sb-options {
    padding: 1rem;
    border-bottom: 1px solid rgba(92, 92, 92, 0.308);
  }

  .sb-graph-view {
    padding: 1rem;
    border-bottom: 1px solid rgba(92, 92, 92, 0.308);
  }

  .sb-detail-view {
    padding: 1rem;
    border-bottom: 1px solid rgba(92, 92, 92, 0.308);
  }
}

.one-liner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 600px) {
  .container {
    grid-template-columns: repeat(4, 1fr);
  }

  .content {
    grid-area: content;
    grid-column: span 3;
  }

  .sidebar {
    grid-area: sidebar;
    grid-column: span 1;
  }

  .toggle {
    display: none;
  }
}

@media only screen and (max-width: 599px) {
  .container .content {
    grid-column: 1 / -1;
  }

  #sidebar {
    transition: transform 0.3s ease-in-out;
    top: 0;
    bottom: 0;
    position: fixed;
    width: 375px;
    right: -380px;
    z-index: 100000;
  }

  #sidebar:target {
    transform: translateX(-380px);
  }

  .close {
    text-align: right;
    display: block;
    text-decoration: none;
    font-size: 3em;
    margin: 5px 15px 0 0;
    position: relative;
    // top: -30px;
  }

  .open {
    text-align: left;
  }

  .flex-column {
    width: 350px;
  }

  .row-content {
    padding: 0px;
    width: 340px;
  }

  .row-content>* {
    padding: 0px;
    margin-top: 15px;
  }

  .content-padding {
    // width: 340px;
    padding: 1.5rem;
  }

  .graph-container {
    padding: 1.5rem;
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px 0;
    grid-template-areas:
      "graph"
      "details";
  }

  .graph-view {
    grid-area: graph;
    // width: 340px;
    grid-row: 1 / 2;
  }

  .graph-detail-view {
    grid-area: details;
    grid-row: 2 / -1;
  }
}