.formInputsHolder {
    display: flex;
    flex-wrap: wrap;

    .form-label {
        width: 370px;
        margin-top: 20px;
    }

    p {
        margin-bottom: 5px;
    }

    .form-error {
        color: red;
        font-size: 12px;
    }

    .input-field {
        border-style: solid;
        width: 90%;
        padding: 8px;
        border-radius: 6px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        display: block;
        border-color: rgb(166, 166, 166);
        height: 40px;
        border-width: 1px
    }

    .select-field {
        width: 90%;
        padding: 8px;
        border-radius: 6px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        display: block;
        height: 40px;
        background-color: white;
    }
}

.contentForm {

    width: 1000px;
    margin: 20px 0 0 20px;
    margin-top: 20px;
    background: rgb(250, 250, 250);
    padding: 5px;
    padding-top: 50px;
    border: 1px solid;
    border-color: rgba(128, 128, 128, 0.151);
    border-radius: 10px;

    .formHeaderHolder {
        width: 95%;
        margin: 0 auto;
        margin-bottom: 20px;
        margin-left: 20px;

        .formHeader h1 {
            font-size: 1.5rem;
        }
    }

    .formSectionHolder {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .formSubmitHolder {
            width: 75%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .btn {
                width: 10em;
                margin: 0;
                margin-bottom: 2em;
            }

            .alert {
                width: 100%;
            }
        }

        .formTitleHolder {
            border-top: 1px solid;
            border-color: gray;
            padding-top: 15px;
            padding-bottom: 15px;
            width: 22%;

            p {
                margin-top: 16px;
                padding-left: 20px;
            }
        }

        .formInputsHolder {

            border-top: 1px solid;
            border-color: gray;
            padding-top: 15px;
            padding-bottom: 15px;
            width: 78%;
            margin-bottom: 20px;
        }

        .formOptionsHolder {
            display: flex;
            flex-direction: column;
            border-top: 1px solid;
            border-color: gray;
            padding-top: 15px;
            padding-bottom: 15px;
            width: 78%;
            margin-bottom: 20px;

            .form-response {
                width: 90%;
                border-radius: 4px;
                padding: 10px;
            }

            .success {
                background-color: rgba(52, 252, 128, 0.842);
            }

            .failure {
                background-color: rgba(255, 114, 49, 0.753);
            }

        }

        .formLastSubmitHolder {
            padding-top: 15px;
            padding-bottom: 15px;
            width: 25%;

            p {
                margin-top: 16px;
            }

        }
    }
}