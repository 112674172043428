

.main-holder {
  max-width: 1000px;
}

.testing {
  max-width: 1020px;
  padding-left: 24px;
  padding-top: 24px;
}

.heading {
  font-weight: 600;
  font-size: 15px;
  color: #2D4369;
  margin-bottom: 10px;
  padding: 0;
}

.heading-activity {  
  border-bottom: 1px solid rgba(100, 102, 102, 0.507);
  margin-bottom: 20px;

}

.profile-description {
  margin-top: 20px;
  max-width: 1020px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .profile-details {
    min-width: 300px;
    margin-bottom: 30px;
    .content-description {
      color: #5D707A;
      margin: 0;
      font-size: 12px;
    }
    .content-details {
      color:  #2D4369;
      font-size: 14px;
    }
  }
}