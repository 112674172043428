.account-options {
    width: 1000px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin-top: 20px;
    background-color: white;

    .investment-options {
        width: 250px;
    }

    .option-holder {
        display: flex;
        flex-direction: row;
    }

    .option-detail {
        padding-top: 20px;
        margin: 0;
    }

    .detail-on {
        padding: 20px 0px 20px 20px;
        margin: 0;
    }

    .detail-off {
        padding: 20px 20px 20px 0px;
        margin: 0;
    }

    .toggle-holder {
        display: flex;
        flex-direction: row
    }

    .selection {
        padding: 0px;
        margin: 0px;
    }

    .toggle {
        margin: 10px 5px 0 5px;
        // padding: 0;
    }

    .line {
        margin-top: 10px;
    }

    .line-pad {
        margin-top: 10px;
        padding-left: 20px;
    }

    .fee-scheme {
        display: flex;
        flex-direction: row;

        .mg-accounts {
            width: 250px;
            padding: 10px;
        }

        .mg-fee {
            width: 100px;
            padding: 10px;
        }

        .mg-icon {
            width: 50px;
            padding-top: 6px;
        }
    }
}

.modal-fee {
    height: 200px;
    padding: 40px;
    background-color: white;
    position: absolute;
    outline: none;
    overflow-y: auto;
    border-radius: 3px;

    .modal-title {
        font-size: 1.5em;
        font-weight: 600;
    }

    .fee-input-form {
        margin-top: 20px;
        display: flex;
        flex-direction: row;

        .management {
            margin-top: 16px;
            width: 300px;
        }

        .fee {
            margin-left: 20px;
            width: 100px;
            margin-top: 16px;
        }

        .button-save {
            margin-top: 23px;
            margin-left: 20px;
            height: 40px;
        }
    }
}