.select-bar {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap, ;
    margin-bottom: 20px;

    .MuiMenuItem-root {
        font-size: 0.9rem;
        // max-height: 25px;
        min-height: 25px;
    }

    .MuiSelect-selectMenu {
        height: 25px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .formControl {
        margin-top: 5px;
        width: 85%;
        padding-bottom: 10px;
    }

    .menu-item {
        color: black;
        padding-bottom: 0px;
    }



}