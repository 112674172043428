.content {
    max-width: 1000px;

    .sectionHolder {
        margin-top: 30px;
    }

    .titleFin {
        margin-bottom: 10px;
        text-align: left;
        margin-left: 13px;
        font-size: 1.3em;
        font-weight: 600;
        color: #444444;
    }

    .cashAccountsHolder {
        padding-top: 20px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .cashHolder {
            margin-top: 10px;
            width: 170px;
            height: 100px;
            background-color: #ffffff;
            padding: 20px;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
            border-radius: 3px;

            .accountTotal {
                color: #575757;
                font-weight: 600;
                font-size: 1.30em;
                margin-bottom: 5px;

            }

            .accountName {
                color: #818181;
                font-weight: 600;
                font-size: 1em;
                margin-bottom: 0px;
            }
        }

        .cashHolder:nth-child(n+2) {
            margin-left: 32px;
        }
    }

    .cashflowHolder {
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        flex-direction: row;

        .countryCashflow {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            margin-bottom: 20px;
            padding: 15px;
            background-color: #ffffff;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            border-radius: 3px;
        }

        .countryTitle {
            margin-left: 10px;
            width: 100%;
            color: #575757;
            font-weight: 600;
            font-size: 1.2em;
        }


        .cashHolder {
            margin-top: 10px;
            width: 74px;
            height: 100px;
            padding: 10px;

            .accountTotal {
                color: #575757;
                font-weight: 600;
                font-size: 0.9em;
                margin-bottom: 5px;

            }

            .accountName {
                color: #818181;
                font-weight: 600;
                font-size: 1em;
                margin-bottom: 0px;
            }
        }
    }

    .countryStatHolder {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-top: 20px;

        .countryStats {
            background-color: #ffffff;
            width: 30%;
            padding: 30px;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);

            .countryTitle {
                margin-bottom: 20px;
                color: #3b3b3b;
                font-weight: 600;
                font-size: 1em;
            }

            .statHolder {
                display: flex;
                flex-direction: row;
                justify-content: space-between;


                .accountTotalTitle {
                    color: #818181;
                    font-weight: 600;
                    font-size: 1em;
                    margin-bottom: 0px;
                }

                .accountTotal {
                    color: #575757;
                    font-weight: 600;
                    font-size: 1em;


                }
            }
        }

        .countryStats:nth-child(n+2) {
            margin-left: 40px;
        }
    }


}