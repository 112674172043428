.table-body {
    margin-top: 10px;
    padding-top: 10px;
    height: 100%;

    h4 {
        margin: 0;
        font-size: 14px;
    }
}

.content-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 52px;
    min-height: 52px;
    font-size: 12px;

    .ser-client {
        white-space: nowrap;
        overflow: hidden;
        margin: 0px;
        margin-top: 7px;
    }
}



.row-height {
    height: 52px;
    min-height: 52;
}

.row-center {
    align-content: center;
}

.gridContainer1>* {
    // border: 1px solid red;
    padding: 0px;
}

.gridContainer>* {
    // border: 1px solid green
}

.ser-item-holder {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 12px;

    .ser-name-country {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 5px 10px 10px 10px;
        padding-left: 20px;

        .ser-client {
            white-space: nowrap;
            overflow: hidden;
            margin: 0px;
            margin-top: 7px;
        }

        span {
            margin: 5px 10px 0px 0px;
        }

        a {
            color: black;
            font-size: 1em;
            font-weight: 600;
            height: auto;
            width: fit-content;
            margin-top: 10px;
            text-decoration: none;
        }
    }

    .ser-businessName {
        width: 100%;
        padding: 10px 10px 0px 10px;
        padding-left: 20px;

    }

    .ser-loanAmount {
        width: 100%;
        padding: 10px 10px 0px 10px;
        padding-left: 20px;
    }

    .loan-amount {
        font-weight: 600;
    }

    .ser-loan-detail {
        width: 100%;
        padding: 10px 10px 0px 10px;

        a {
            color: black;
        }
    }

}

.table-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: #00446B;

    .header {
        padding: 5px 0px 5px 5px;
        font-size: 1em;
        font-weight: 600;
        color: white;
    }

    .header-name {
        width: 100%;
        padding-left: 20px;
    }

    .header-company {
        width: 100%;
    }

    .header-loan-number {

        width: 100%;
    }

    .header-capital {
        width: 100%;
    }

    .header-remaining {
        width: 100%;
    }

    .header-payment {
        width: 100%;
    }

    .header-date {
        width: 100%;
    }
}

.pure-material-checkbox {
    padding: 0px 9px 0px 0px !important;
    margin-top: 0px !important;
}

@media all and (max-width: 767px) {
    .row-height {
        height: 100%;
        padding: 20px;
    }

    .header {
        padding-left: 40px;
        font-size: 1em;
        font-weight: 600;
        color: white;
    }

    .MuiGrid-spacing-xs-2>.MuiGrid-item {
        padding-left: 20px;
    }
}