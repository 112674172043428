.main-holder {
    .borrower-profile {
        margin-top: 20px;
        width: 100%;
    }
    max-width: 1000px;
    .profile-actions {
        max-width: 100%;
        padding: 10px 0px 10px 0px;
        border-bottom: 1px solid rgb(235, 235, 235),
        rgb(145, 145, 145)
    }
    .client-header {
        margin: 15px;
        margin-left: 20px;
        width: 100%;
        border-bottom: 2px solid rgba(50, 96, 102, 1);
        .client-fullname {
            font-size: 1.2rem;
            font-weight: 600px;
        }
    }


}

.main-holder {
    margin-top: 20px;
    width: 1000px;
    .client-loan-summary-head {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 50px;
        padding: 5px 0 5px 0;
        background-color: rgb(211, 211, 211);
        .detail-summary {
            display: flex;
            flex-direction: column;
            height: fit-content;
            padding: 10px;
            padding-left: 10px;
            min-width: 125px; 
            .title {
                margin-left:auto;
                margin-right:0;
                font-weight: 600;
            }
        }
        .pct {
            min-width: 100px;
        }
        .fc {
            margin-left:0;
            margin-right: auto;
        }
        .first {
            min-width: 125px; 
        }
        .last {
            min-width: 40px;
        }

    }

.client-loan-summary {
    
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 30px;
    border-bottom: 1px solid;
    border-color: rgba(207, 207, 207, 0.322);
    background-color: rgb(0, 68, 107);
    .detail-summary {
        display: flex;
        flex-direction: column;
        padding: 10px;
        padding-top: 20px;
        padding-left: 20px;
        height: fit-content;
        min-width: 125px;
        .acc-total {
            color: white;
            font-weight: 500;
            margin-left:auto;
            margin-right:0;
        }
    }
    .pct {
        min-width: 100px;
    }
    .first {
        min-width: 125px; 
    }
    .fc {
        margin-left:0;
    }
    .last {
        min-width: 40px;
    }

}
// .client-loan-summary:last-child {
//     border-bottom-left-radius: 10px;
//         border-bottom-right-radius: 10px; 
// }
}
